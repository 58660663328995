// SubscriptionDetails.js
import React from 'react';
import SubscriptionStar from '../../assets/icons/subscriptionStar.png';
import SubscriptionAdvance from '../../assets/icons/subscriptionAdvance.png';
import SubscriptionPremium from '../../assets/icons/subscriptionPremium.png';

import css from './SubscriptionDetails.module.css';
const SubscriptionDetails = ({
  metadata,
  isExpired,
  formattedExpiryDate,
  paidOn,
  cardNumber,
  cardType,
}) => {
  return (
    <div className={css.subscriptionDetailsContainer}>
      {(metadata.subscriptionStatus === 'active' || metadata.subscriptionStatus === 'stopped') && (
        <div>
          {metadata.subscriptionName === 'Basic' && (
            <img className={css.subsciptionStar} src={SubscriptionStar} alt="Basic Subscription" />
          )}
          {metadata.subscriptionName === 'Advance' && (
            <img
              className={css.subsciptionStar}
              src={SubscriptionAdvance}
              alt="Advance Subscription"
            />
          )}
          {metadata.subscriptionName === 'Premium' && (
            <img
              className={css.subsciptionStar}
              src={SubscriptionPremium}
              alt="Premium Subscription"
            />
          )}
        </div>
      )}
      <div>
        <span>
          {(metadata.subscriptionStatus === 'active' ||
            metadata.subscriptionStatus === 'stopped') && (
            <div className={css.subscriptionType}>{metadata.subscriptionName} Subscription</div>
          )}
          {paidOn && cardType && cardNumber && (
            <>
              <div className={css.renewalStatus}>Paid on {paidOn}</div>
              <div className={css.renewalStatus}>
                {cardType} ****** **{cardNumber}
              </div>
            </>
          )}
          <div className={css.renewalStatus}>
            {metadata.subscriptionStatus === 'active' && 'auto-renewed on '}
            {metadata.subscriptionStatus === 'stopped' && 'ending on '}
            {/* {metadata.subscriptionStatus !== 'active' &&
              metadata.subscriptionStatus !== 'stopped' &&
              'No Subscription'} */}
            {metadata.subscriptionStatus === 'active' && metadata.subscriptionExpiry
              ? formattedExpiryDate
              : null}
            {metadata.subscriptionStatus === 'stopped' && metadata.subscriptionExpiry
              ? formattedExpiryDate
              : null}
          </div>
        </span>
      </div>
      {metadata.subscriptionStatus === 'active' && (
        <div className={css.activeSubscription}>Recurring</div>
      )}
      {metadata.subscriptionStatus === 'stopped' && (
        <div className={css.activeSubscription}>Active</div>
      )}
      {/* {metadata.subscriptionStatus !== 'active' && metadata.subscriptionStatus !== 'stopped' && (
        <div className={css.expiredSubscription}></div>
      )} */}
    </div>
  );
};

export default SubscriptionDetails;
