import React from 'react';
import css from './ListingPage.module.css';
import { formatCurrency, calcAge } from '../../util/formatHelpers';

const SectionDetails = props => {
  const { publicData, metadata = {}, listingConfig, intl } = props;

  if (!publicData || !listingConfig) {
    return null;
  }

  const {
    industry,
    e_commerce_type,
    technology,
    age,
    average_profit_margin,
    last_month_turnover,
    last_12_months_turnover,
    marketingChannel,
  } = publicData;

  const detailItems = [
    ['Industry', industry ? industry : ''],
    ['E-Commerce type', e_commerce_type ? e_commerce_type : ''],
    ['Technology', technology ? technology : ''],
    ['Business Age', age ? calcAge(age) : 0],
    ['Avg. profit margin', average_profit_margin ? average_profit_margin + '%' : 0],
    ['Turnover rate', last_month_turnover ? formatCurrency(last_month_turnover) + ' €' : 0],
    [
      '12 Months turnover',
      last_12_months_turnover ? formatCurrency(last_12_months_turnover) + ' €' : 0,
    ],
    [
      'Marketing Channel',
      marketingChannel && Array.isArray(marketingChannel) ? marketingChannel.join(', ') : '',
    ],
  ];

  const rows = [];

  for (let i = 0; i < detailItems.length; i += 4) {
    const chunk = detailItems.slice(i, i + 4);
    rows.push(
      <div key={i} className={css.listingDetailsContainer}>
        {chunk.map(([title, value], index) => (
          <div key={index} className={css.detailsContainer}>
            <div className={css.publicDataTitle}>{title}</div>
            <div>{value}</div>
          </div>
        ))}
      </div>
    );
  }

  return <div>{rows}</div>;
};

export default SectionDetails;
